import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

export class EditItem2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hash: "",
            isFree: false,
            title: "",
            tags: [],
            canSave: true
        }
    }

    componentDidMount() {
        this.setState({
            hash: this.props.item.hash,
            isFree: this.props.item.isFree,
            title: this.props.item.title,
            tags: this.props.item.tags
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item.hash !== this.props.item.hash) {
            this.setState({
                hash: this.props.item.hash,
                isFree: this.props.item.isFree,
                title: this.props.item.title,
                tags: this.props.item.tags
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '200px auto auto 100px 100px',
                        justifyContent: 'stretch'
                    }}
                >
                    <span
                        style={{
                            border: '1px solid #f1f1f1',
                            padding: 10
                        }}
                    >
                        <img
                            src={`https://v3.pdm-plants-textures.com/images/files/${this.state.hash.substring(0, 2)}/${this.state.hash}.${this.props.item.thumbnailExt}`}
                            alt=""
                            style={{ height: 60 }}
                        />
                    </span>
                    <textarea
                        style={{
                            padding: 7.5,
                            border: '1px solid #f1f1f1'
                        }}
                        defaultValue={this.state.title}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setState({
                                title: value
                            })
                        }}
                    />
                    <textarea
                        style={{
                            padding: 7.5,
                            border: '1px solid #f1f1f1'
                        }}
                        defaultValue={this.state.tags.join(', ')}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setState({
                                tags: value.split(',').map((tag) => tag.trim())
                            })
                        }}
                    />
                    <span
                        style={{
                            border: '1px solid #f1f1f1',
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}
                    >
                        <input
                            type="checkbox"
                            defaultChecked={this.state.isFree}
                            style={{
                                width: 20,
                                height: 20
                            }}
                            onChange={(e) => {
                                const value = e.target.checked;
                                this.setState({
                                    isFree: value
                                })
                            }}
                        />
                    </span>
                    <span
                        style={{
                            border: '1px solid #f1f1f1',
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}
                    >
                        <Button
                            variant="dark"
                            disabled={!this.state.canSave}
                            onClick={(e) => {
                                this.setState({
                                    canSave: false
                                }, () => {
                                    this.props.item.isFree = this.state.isFree;
                                    this.props.item.tags = this.state.tags;
                                    this.props.item.title = this.state.title;
                                    var params = {
                                        TableName: "Items",
                                        Key: {
                                            "id": this.props.item.id
                                        },
                                        UpdateExpression: "set isFree=:isFree, tags=:tags, title=:title",
                                        ExpressionAttributeValues: {
                                            ":isFree": this.props.item.isFree,
                                            ":tags": this.props.item.tags,
                                            ":title": this.props.item.title
                                        },
                                        ReturnValues: "UPDATED_NEW"
                                    };
                                    let editItem = this;
                                    window.docClient.update(params, function (err, data) {
                                        if (err) {
                                            console.log(err);
                                        } else {
                                            console.log(data);
                                        }
                                        editItem.setState({
                                            canSave: true
                                        })
                                    });
                                })
                            }}>
                            Save
                        </Button>
                    </span>
                </div>
            </React.Fragment>
        );
    }
}